<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-tab
        :dense="true"
        :tabItems="tabItems"
        :height="tabHeight"
        :inlineLabel="true"
        v-model="tab"
        @tabClick="tabClick"
      >
        <template v-slot:default="tab">
          <component
            :count="count"
            :popupParam.sync="popupParam"
            :equipInfo.sync="equipInfo"
            :validPeriod.sync="validPeriod"
            :is="tab.component"
            :attachInfo.sync="attachInfo"
            :attachInfo2.sync="attachInfo2"
            @closePopup="closePopup"
            @saveCallback="saveCallback"
            @changeStatus="changeStatus"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import { uid } from 'quasar'
export default {
  name: 'hazard-equipment-class-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        hhmHazardousMachineryId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tabDisabled: false,
      tab: 'hazardEquipmentInfo',
      addTabItems: [],
      tabItems: [],
      count: 0,
      validPeriod: [],
      equipInfo: {
        hhmHazardousMachineryId: '',
        validPeriod: [],
        equipmentTypeCd: '', 
        equipmentCd: '', 
        equipmentTypeName: '',
        equipmentName: '',
        plantCd: null,
        machineryClassification: null,
        itemNo: '',
        inspectionKindCd: null,
        manufacturer: '',
        manufactDate: '',
        emplacementPlace: '',
        emplacementMonth: '',
        demolitionMonth: '',
        passNumber: '',
        ewrNo: '',
        amount: '',
        esignBasisRatedLoad: '',
        esignBasisKind: '',
        inspCertificateIssuanceDate: '',
        lastInspectionDate: '',
        inspectionSubjectFlag: null,
        inspectionCycle: null,
        nextInspectionDate: '',
        reasonExemptionInspection: '',
        machineryName: '',
        gradeCd: null,
        departmentDeptCd: '',
        departmentUserId: '',
        relatedLaws: '',
        remark: '',
        regUserId: '',
        chgUserId: '',
        subProcessCd: '',
        useFlag: 'Y',
        protectDevice: '',
        volume: '',
        validStartDate: '',
        validEndDate: '',
        possibleDisaster: '',
        hazardEquipmentHistoryModels: [],
        equipmentImproveModels: [],
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'HAZARD_EQUIPMENT_INFO',
        taskKey: '',
      },
      attachInfo2: {
        isSubmit: '',
        taskClassCd: 'HAZARD_EQUIPMENT_PICTURE',
        taskKey: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 45);
    },
  },
  watch: {
  },
  methods: {
    init() {
      this.getUrl = selectConfig.sop.hhm.hazard.check.get.url;
      this.tabItems = [
        // 유해위험기계기구 정보
        { name: 'hazardEquipmentInfo', icon: 'info', label: 'LBL0002518', component: () => import(`${'./hazardEquipmentInfo.vue'}`), key: uid()  },
      ]
      this.addTabItems = [
        // 검사이력 관리
        { name: 'hazardEquipmentHistory', icon: 'history', label: 'LBL0002519', component: () => import(`${'./hazardEquipmentHistory.vue'}`), key: uid()  },
        // 개선관리
        { name: 'hazardCausePrevention', icon: 'construction', label: 'LBL0002520', component: () => import(`${'./hazardCausePrevention.vue'}`), key: uid()  },
      ]
      if (this.popupParam.hhmHazardousMachineryId) {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      } 
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.hhmHazardousMachineryId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.hhmHazardousMachineryId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          // 빈값들 filter
          this.$_.extend(this.equipInfo, _result.data);
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)

          if (_result.data.validStartDate && _result.data.validEndDate) {
            this.equipInfo.validPeriod = [_result.data.validStartDate, _result.data.validEndDate]
          }
          this.$set(this.attachInfo, 'taskKey', this.popupParam.hhmHazardousMachineryId);
          this.$set(this.attachInfo2, 'taskKey', this.popupParam.hhmHazardousMachineryId);
          this.updateMode = true;
        },);
      }
    },
    changeStatus() {
      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
    closePopup() {
      this.$emit('closePopup')
    },
    tabClick() {
      this.getDetail();
    },
    saveCallback(data) {
      this.popupParam.hhmHazardousMachineryId = data;
      this.getDetail();
    },
  }
};
</script>